import Search from '@/components/pages/Home/Search';
import CategoryList from '@/components/sections/CategoryList';

import dynamic from 'next/dynamic';
const WelcomeMessage = dynamic(
  () => import('@/components/pages/Home/Unauthenticated/WelcomeMessage'),
  {
    ssr: false,
  },
);

export default function HomeSliderBlock({
  welcomeText,
  featuredCategories,
  headline,
  subheadline,
}) {
  return (
    <>
      <div className="home-slider-block">
        <h2>{headline}</h2>
        <p>{subheadline}</p>
        {welcomeText && <WelcomeMessage welcomeText={welcomeText} />}

        <Search />
        <CategoryList featuredCategories={featuredCategories} />
      </div>
    </>
  );
}
