import ManyDesignLayoutsBlock from '@/components/pages/Home/Unauthenticated/ManyDesignLayoutsBlock';
import CleanKosherBlock from '@/components/pages/Home/Unauthenticated/CleanKosherBlock';
import MagicAutofillBlock from '@/components/pages/Home/Unauthenticated/MagicAutofillBlock';
import KosherAIBlock from '@/components/pages/Home/Unauthenticated/KosherAIBlock';
import PartnersBlock from '@/components/pages/Home/Unauthenticated/PartnersBlock';
import FeaturedTemplates from '@/components/pages/Home/Unauthenticated/FeaturedTemplates';
import HomeSliderBlock from '@/components/pages/Home/HomeSliderBlock';
import dynamic from 'next/dynamic';

const HomeKGFeaturesBlock = dynamic(
  () => import('@/components/pages/Home/Unauthenticated/HomeKGFeaturesBlock'),
  { ssr: false },
);
import { useSiteInfo } from '@/context/SiteInfoContext';

export default function Unauthenticated({
  welcomeText,
  featuredCategories,
  featuredProducts,
  headline,
  subheadline,
}) {
  const { siteInfo } = useSiteInfo();
  return (
    <>
      <HomeSliderBlock
        welcomeText={welcomeText}
        featuredCategories={featuredCategories}
        headline={headline}
        subheadline={subheadline}
      />

      <FeaturedTemplates featuredProducts={featuredProducts} />

      <CleanKosherBlock />

      <ManyDesignLayoutsBlock />

      <MagicAutofillBlock />

      <KosherAIBlock />

      {siteInfo && siteInfo.pro_subscription ? (
        <HomeKGFeaturesBlock products={featuredProducts} />
      ) : null}

      <PartnersBlock />
    </>
  );
}
